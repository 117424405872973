import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useStep } from 'react-hooks-helper';
import { navigate } from 'gatsby';
import { usePasswordValidation } from 'components/hooks';
import Step1 from './step1';
import Step2 from './step2';
import { resetPassword } from 'data/auth';

const steps = [
  { id: 'step1' },
  { id: 'step2' },
];

const ResetPassword: React.VFC<any> = ({ userId, token }) => {
  const [isValidated, setIsValidated] = useState<boolean>(false);
  const [form, setForm] = useState({
    password: '',
    passwordConfirmation: '',
  });
  const { step, navigation } = useStep({ initialStep: 0, steps });
  const mutation = useMutation(resetPassword, {
    onSuccess: (success) => {
      if (success) {
        navigation.next();
      } else {
        navigate('/login');
      }
    }
  });

  const {
    isMatched,
    isValidLength,
    hasSpecialChar,
  } = usePasswordValidation({
    password: form.password,
    passwordConfirmation: form.passwordConfirmation,
    requiredLength: 8,
  });

  const handleChange = (event): void => {
    event.persist();

    setIsValidated(true);
    setForm(values => ({
      ...values,
      [event.target.name]: event.target.value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValidated && isMatched && isValidLength && hasSpecialChar) {
      mutation.mutate({
        password: form.password,
        userId,
        token
      });
    }
  };

  const { id } = step;
  const props = {
    form,
    isValidated,
    isMatched,
    isValidLength,
    hasSpecialChar,
    handleChange,
    handleSubmit,
  };

  const renderStep = (id) => {
    switch (id) {
      case 'step1':
        return <Step1 {...props} />;
      case 'step2':
        return <Step2 />;
      default:
        return null;
    }
  };

  return (
    <>
      {renderStep(id)}
    </>
  );
};

export default ResetPassword;
