import React from 'react';
import { ButtonLink } from 'components';

const Step2 = () => {
  return (
    <div className="register__step">
      <h2 className="register__step-title">Reset your password</h2>
      <p className="register__step-subtitle mt-0 mb-5">
        Your Voicio password has been changed successfully, you can now login
        with your new Voicio password.
      </p>
      <ButtonLink
        large
        round
        to="/login"
        className="register__step-btn mb-3"
        sizeX={'182px'}
      >
        Back to Login
      </ButtonLink>
    </div>
  );
};

export default Step2;
