import React from 'react';
import { useRecoilStateLoadable } from 'recoil';
import { navigate } from 'gatsby';
import { AuthPageWrapper, Link, BrandLogo } from 'components';
import ResetPasswordForm from 'templates/authentication/resetPassword';
import { AuthUserState } from 'data/auth';

const ResetPassword: React.VFC<any> = ({ location }) => {
  const [user] = useRecoilStateLoadable(AuthUserState);
  const params = new URLSearchParams(location.search);  
  const userId = params.get('user_id');
  const token = params.get('token');

  React.useEffect(() => {
    if (!userId || !token) {
      navigate('/login');
    }
    if (user.state === 'hasValue' && user?.contents?.authenticated) {
      navigate('/dashboard');
    }
  }, [user, userId, token]);
  
  return (
    <AuthPageWrapper>
      <Link to='/'>
        <BrandLogo className='register__logo' />
      </Link>
      <div className='register__steps'>
        <ResetPasswordForm 
          token={token}
          userId={userId}
        />
      </div>
      <div className='register__footer'>
        © {(new Date().getFullYear())} Voicio. Alright Reserved.
      </div>
    </AuthPageWrapper>
  );
};

export default ResetPassword;
