import React from 'react';
import { Form, Link, Button } from 'components';
import InvalidIcon from 'assets/image/icons/register/invalid.svg';

type Props = {
  form: any,
  isValidated: boolean,
  isMatched: boolean,
  isValidLength: boolean,
  hasSpecialChar: boolean,
  handleChange: (event: any) => void,
  handleSubmit: (event: any) => void,
}
const Step1: React.FC<Props> = ({
  form,
  isValidated,
  isMatched,
  isValidLength,
  hasSpecialChar,
  handleChange,
  handleSubmit,
}) => {
  const isValid = isMatched && isValidLength && hasSpecialChar;

  return (
    <div className="register__step">
      <h2 className="register__step-title mb-5">Let`s reset your password</h2>
      <Form noValidate onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Input
            className="text-center"
            name="password"
            type="password"
            placeholder="Password"
            value={form.password || ''}
            isValid={isValidated && isValid}
            onChange={handleChange}
            round
            required
          />
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Input
            className="text-center"
            name="passwordConfirmation"
            type="password"
            placeholder="Repeat your password"
            value={form.passwordConfirmation || ''}
            isValid={isValidated && isValid}
            onChange={handleChange}
            round
            required
          />
        </Form.Group>
        <div className="register__step-error-guide">
          {isValidated && !isValidLength && (
            <p><InvalidIcon /> Needs to be at least 6 characters</p>
          )}
          {isValidated && !hasSpecialChar && (
            <p><InvalidIcon /> Needs to have at least one special character</p>
          )}
          {isValidated && !isMatched && (
            <p><InvalidIcon /> Passwords do not match</p>
          )}
        </div>
        <Button
          variant="secondary"
          large
          round
          className="mb-5"
          sizeX={'182px'}
          disabled={!isValid}
        >
          Continue&nbsp;&nbsp; <i className="fas fa-long-arrow-alt-right"></i>
        </Button>
      </Form>
      <div className="register__step-forgot-option">
        <Link
          to="/login"
          className="register__step-forgot-option-anchor"
        >
          Back to login
        </Link>
      </div>
    </div>
  );
};

export default Step1;
